<template>
    <div class="mod-config">
        <div style="width: 97%;height:100%;margin: auto;background: white;box-sizing: border-box;padding: 22px;">
<!--            <div class="sousuo" style="height: 41px;line-height: 41px; box-sizing: content-box;margin-bottom: 20px;">-->
                <el-form :inline="true"  class="demo-form-inline">
                    <el-form-item label="基地名称" prop="jidiValue">
                    <el-select style="background-color: #E5E5E5;border: 1px solid #E5E5E5; border-radius: 4px;"
                               v-model="jidiValue" filterable collapse-tags :clearable="true"  placeholder="全部">
                        <el-option
                                v-for="item in jidiName"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"/>

                    </el-select>
                </el-form-item>
                    <el-form-item label="大棚名称" prop="dapengValue">
                        <el-select style="background-color: #E5E5E5;border: 1px solid #E5E5E5; border-radius: 4px;"
                                   v-model="dapengValue" filterable collapse-tags :clearable="true"  :placeholder="jidiValue==''?'请选择基地':'全部'">
                            <el-option
                                    v-for="item in dapengName"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"/>

                        </el-select>
                    </el-form-item>

                    <el-form-item label="选择日期" prop="selectDay">
                        <el-date-picker
                                v-model="selectDay"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOPtions"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="chaxun()" style="margin-left: 25px; background: linear-gradient(0deg, #1ECC68, #14B651, #0E902F);border: 1px solid rgba(114,114,114,0.22);
                    border-radius: 4px; color: white;font-size: 16px; width: 100px; height: 40px;">
                            查询
                        </el-button>
                        <el-button @click="chognzhi" style="margin-left: 25px; background: linear-gradient(0deg, #1ECC68, #14B651, #0E902F);border: 1px solid rgba(114,114,114,0.22);
                    border-radius: 4px; color: white;font-size: 16px; width: 100px; height: 40px;">
                            重置
                        </el-button>
                    </el-form-item>
                </el-form>



<!--                <download-excel style="float:right;display: inline-block"-->
<!--                                class="export-excel-wrapper"-->
<!--                                :data="DetailsForm"-->
<!--                                :fields="json_fields"-->
<!--                                :header="title"-->
<!--                                name="预警报警记录.xls">-->
<!--                    &lt;!&ndash; 上面可以自定义自己的样式，还可以引用其他组件button &ndash;&gt;-->
<!--                    <el-button type="success" class="daochu" icon="el-icon-sold-out">导出</el-button>-->
<!--                </download-excel>-->
<!--            </div>-->
            <el-table
                    style="height: 620px;"
                    :data="waringList"
                    border
                    :row-class-name="tableRowClassName"
                    @selection-change="handleSelectionChange">
                <el-table-column width="50"
                                 type="selection">
                </el-table-column>
                <el-table-column
                                 width="50"
                                 prop="myXuhao"
                                 label="序号"
                >
                </el-table-column>

                <el-table-column
                        width="200"
                        prop="baseName"
                        label="基地名称"
                >
                </el-table-column>
                <el-table-column
                        width="130"
                        prop="greenhouseName"
                        label="大棚名称">
                </el-table-column>
                <el-table-column
                        width="250"
                        prop="provinceName,cityName,districtName,townName"
                        label="基地归属地"
                >
                    <template slot-scope="scope">
                        {{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}{{scope.row.townName}}
                    </template>
                </el-table-column>

                <el-table-column
                                 width="500"
                                 prop="remark"
                                 label="报警项目名称">
                </el-table-column>
                <el-table-column
                                 width="100"
                                 prop="conditions"
                                 label="触发条件">
                </el-table-column>
                <el-table-column
                                 width="100"
                                 prop="value"
                                 label="触发值">
                </el-table-column>
                <el-table-column
                        width="200"
                        prop="time"
                        label="报警时间"
                ></el-table-column>
<!--                <el-table-column-->
<!--                                 prop="status"-->
<!--                                 label="处理状态">-->
<!--                    <template slot-scope="scope">-->
<!--                        <span>{{scope.row.status===0?'未处理':(scope.row.status===1?'已处理':"")}}</span>-->
<!--                    </template>-->
<!--                </el-table-column>-->

<!--                <el-table-column-->
<!--                        width="150"-->
<!--                        prop="date"-->
<!--                        label="操作">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button class="xiangqing"-->
<!--                                   size="mini"-->
<!--                                   @click="handleDetale(scope.$index, scope.row)-->
<!--                                ">删除-->
<!--                        </el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->


            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="totalPage"
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>


    </div>
</template>


<script>
    export default {
        components: {},
        data() {
            return {
                title:'预警报警记录',
                tableData: [],
                dialogVisible: false,
                value1: '',
                selectDay:'',//选择日期
                start:'',//开始日期
                finish:'',//结束日期
                pickerOPtions: {
                    disabledDate(time){
                        return time.getTime()>=Date.now()
                    }
                },
                waringList: [],//预警报警记录
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                selectedNum:0,//选中记录数量
                indexs:[],
                changeList: [],
                jidiValue:'',//基地id
                dapengValue:'',//大棚id
                jidiName:[],//基地列表
                dapengName:[],//大棚列表
                json_fields: {
                    "基地名称":'baseName',
                    "基地归属地":'district',
                    "大棚名称":'greenhouseName',
                    "报警项目名称":'remark',
                    "触发条件":'conditions',
                    "触发值":'value',
                    "报警时间":'time',

                },
                DetailsForm:[]
            }
        },
        mounted() {
            this.getDataNameList();
            this.getDataList();
            this.$bas.$emit("suiji",'/qiheWarn')

        },
        methods: {
            handleDetale(e,a){
                console.log('接收',e,a)
                const id =a.id;
                const name=a.name;
                this.$api.get('error/'+'['+id+']', {}, result => {
                })
            },
            headerStyleEvent({ rowIndex }){
                // if (rowIndex == 0||rowIndex == 1){
                //     return 'background:linear-gradient(0deg, #E6E6E6, #FFFFFF) !important;height:50px;'
                // }else {
                //     return 'background:#ffffff !important;height:50px;'
                // }
            },
            //重置
            chognzhi(){
                this.start=''
                this.finish=''
                this.selectDay=''
                this.jidiValue=''
                this.dapengValue=''
                this.getDataList()
            },
            //查询
            chaxun(){
                console.log('基地id'+this.jidiValue);
                console.log('大棚id'+this.dapengValue);
                console.log('日期'+this.selectDay);
                if (this.selectDay !=""&&this.selectDay!=null&&this.selectDay!=undefined){
                    let riqi=this.selectDay.slice(",");
                    this.start=riqi[0]+' 00:00:00'
                    this.finish=riqi[1]+' 23:59:59'
                    console.log('日期'+this.start+"-----"+this.finish);
                    console.log('日期'+this.selectDay);

                }else{
                    this.start=''
                    this.finish=''
                }
                this.pageIndex=1

                this.getDataList()
            },
            //基地列表
            getDataNameList(){
                this.$api.get('base/getlist', {}, result => {
                    this.jidiName = result.rows
                })
            },
            getDataPengList(newBid){
                this.$api.get('gHouse/getGreenhouseList?bid='+newBid, {}, result => {
                // this.$api.get('gHouse/getGreenhouseList?bid=1', {}, result => {
                    this.dapengName = result.list
                  if (result.list.length>0) this.dapengValue=result.list[0].id
                })
            },
            //列表数据
            getDataList(){
                this.$api.get('error/getAllGreenhouses',
                    {pageNum:this.pageIndex,pageSize:this.pageSize,bId:this.jidiValue,gid:this.dapengValue,start:this.start,end:this.finish},
                    result => {
                    this.waringList = result.list.rows
                    this.totalPage = result.list.total
                })
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
                //获取所有选中项的gh(工号)属性的值
                this.indexs = selection.map(item => item)
                //获取所有选中项数组的长度
                //获取所有选中项数组的长度
                this.selectedNum = selection.length
                this.DetailsForm.length=0
                this.indexs.forEach((item,index) =>{
                    this.DetailsForm[index]=item
                    this.DetailsForm[index].district=item.provinceName+item.cityName+item.districtName+item.townName
                })
            },
            tableRowClassName({rowIndex}) {
                // if (rowIndex % 2 == 1) {
                //     return 'warning-row';
                // } else if (rowIndex === 3) {
                //     return 'success-row';
                // }
                return '';
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                this.getDataList()
            },

        },
        watch: {
            'jidiValue': {//监听第一个选择器的值变化
                handler(val, oldVal) {
                    console.log('val',val)
                    this.dapengValue=''
                    if (val>0) this.getDataPengList(val);
                    else {
                        this.dapengName.length=0
                    }
                },

            }
        }
    }
</script>

<style scoped>
    .daochu{
        width: 86px;
        height: 40px;
        background: #E7FBEF;
        border: 2px solid #18C15C;
        border-radius: 4px;
        padding: 0;
        float: right;
        margin: 0 23px 0 24px;
        font-weight: 500;
        color: #009E4F;
    }
    .daochu:hover{
        color: #166D66;
    }
    /deep/.el-icon-sold-out:before{
        /*width: 18px !important;*/
        /*height: 18px !important;*/
        content: url("../../assets/basegreenhouse/ic_export.png");
        vertical-align: middle;
        display: inline-block;
    }
    /deep/.el-table-column{
        background-color: #f6f6f6 !important;
    }
    .xiangqing{
        width: 86px;
        height: 33px;
        background: url("../../assets/basegreenhouse/btn_green_small.png") no-repeat;
        border: none;
    }
    .xiangqing:hover{
        color: #166D66;
    }
    /deep/.el-table-column{
        background-color: #f6f6f6 !important;
    }
    /deep/.el-table th {
        padding: 0;
        margin: 0;
        height: 50px;
        background-color: #f6f6f6 !important;
    }
    /deep/ .el-table tbody tr:hover > td {
        background-color: #fdfdfd !important;
    }
    .tabLst-title {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1A99F2;
        cursor: pointer;
        margin-bottom: 10px;
    }

    .warning-box {
        background: #FFF000;
        margin-top: 20px;
        padding: 30px 36px;
        min-height: 85vh;
    }
    .mod-config{
        width: auto;
        bottom: 20px;
        right: 0px;
        left:0px;
        top: 93px;
        position: absolute;
        margin-left: 240px;
        box-sizing: border-box;
    }

    .el-table{
        box-sizing: content-box;

    }
    /deep/.sousuo .el-select{
        background: none !important;
        border: none !important;
    }

    /*.el-input.el-input--suffix {*/
    /*// 修改背景颜色、字体颜色、边框、宽高*/
    ::v-deep.sousuo .el-select .el-input__inner {
        background: #F1F7F5 !important;
        border: 1px solid #E5E5E5;
        color: #333333;
        height: 41px;
        width: 268px;
    }

    /deep/
    .sousuo .el-select .el-input.is-focus .el-input__inner{
        border: 1px solid #166D66 !important;
    }

    /deep/.sousuo.el-select .el-select .el-input__inner:focus{
        border: 1px solid #166D66 !important;
    }
    /deep/.el-pager li.active{
        color: #009E4F !important;
    }
    .el-pagination{
        margin-top: 36px;
        text-align: center;
    }
</style>
